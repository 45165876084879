import { ePermissionIds } from "@loadsure/core";
import { iRoute } from "../types/types";

export const EditPortalSettingsRoutes: iRoute[] = [
  {
    paths: ["/portal/settings"],
    permissions: [
      ePermissionIds.VIEW_BASIC_SETTINGS,
      ePermissionIds.EDIT_BASIC_SETTINGS,
      ePermissionIds.VIEW_INVOICING_SETTINGS,
      ePermissionIds.EDIT_INVOICING_SETTINGS,
      ePermissionIds.VIEW_RELEASE
    ],
    allowAccessAll: false
  }
];

export const PartnerSettingsRoutes: iRoute[] = [
  {
    paths: ["/partner/settings"],
    permissions: [
      ePermissionIds.VIEW_BASIC_SETTINGS,
      ePermissionIds.EDIT_BASIC_SETTINGS
    ],
    allowAccessAll: false
  }
];

export const EditMasterPoliciesRoutes: iRoute[] = [
  {
    paths: ["/partner/masterPolicies"],
    permissions: [
      ePermissionIds.VIEW_INSURANCE_SETTINGS,
      ePermissionIds.EDIT_INSURANCE_LICENSES,
      ePermissionIds.VIEW_MASTER_POLICIES
    ],
    allowAccessAll: false
  }
];

export const UsersRoutes: iRoute[] = [
  {
    paths: ["/users"],
    permissions: [
      ePermissionIds.VIEW_USERS,
      ePermissionIds.EDIT_USERS,
      ePermissionIds.DELETE_USERS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/users/:id"],
    permissions: [ePermissionIds.VIEW_USERS, ePermissionIds.EDIT_USERS],
    allowAccessAll: false
  },
  {
    paths: ["/users/NEW"],
    permissions: [ePermissionIds.EDIT_USERS],
    allowAccessAll: false
  }
];

export const PartnersRoutes: iRoute[] = [
  {
    paths: ["/partners"],
    permissions: [
      ePermissionIds.VIEW_PARTNERS,
      ePermissionIds.EDIT_PARTNERS,
      ePermissionIds.DELETE_PARTNERS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/partners/:id", "/partners/:partnerId/users"],
    permissions: [
      ePermissionIds.VIEW_PARTNERS,
      ePermissionIds.EDIT_PARTNERS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/partners/:partnerId/users/:id"],
    permissions: [
      ePermissionIds.EDIT_PARTNERS,
      ePermissionIds.EDIT_USERS,
      ePermissionIds.DELETE_USERS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/partners/NEW"],
    permissions: [ePermissionIds.EDIT_PARTNERS],
    allowAccessAll: false
  }
];

export const SanctionsChecksRoutes: iRoute[] = [
  {
    paths: ["/admin/supportData/:id"],
    permissions: [
      ePermissionIds.VIEW_SANCTION_CHECKS,
      ePermissionIds.EDIT_SANCTION_CHECKS
    ],
    allowAccessAll: false
  },
  {
    paths: ["/admin/supportData/sanctionsChecks"],
    permissions: [
      ePermissionIds.VIEW_SANCTION_CHECKS,
      ePermissionIds.EDIT_SANCTION_CHECKS
    ],
    allowAccessAll: false
  }
];

export const TaxesRoutes: iRoute[] = [
  {
    paths: ["/admin/taxes"],
    permissions: [ePermissionIds.VIEW_TAXES, ePermissionIds.EDIT_TAXES],
    allowAccessAll: false
  },
  {
    paths: ["/admin/taxes/:id"],
    permissions: [ePermissionIds.EDIT_TAXES],
    allowAccessAll: false
  }
];

export const LicensingRoutes: iRoute[] = [
  {
    paths: ["/admin/licensing"],
    permissions: [
      ePermissionIds.VIEW_INSURANCE_LICENSES,
      ePermissionIds.EDIT_INSURANCE_LICENSES
    ],
    allowAccessAll: false
  },
  {
    paths: [ "/admin/licensing/:id"],
    permissions: [ePermissionIds.EDIT_INSURANCE_LICENSES],
    allowAccessAll: false
  }
];

export const PartnerInvoiceRoutes: iRoute[] = [
  {
    paths: ["/partner/invoices"],
    permissions: [
      ePermissionIds.VIEW_MY_INVOICES,
      ePermissionIds.VIEW_COMPANY_INVOICES,
      ePermissionIds.VIEW_ALL_INVOICES
    ],
    allowAccessAll: false
  }
];
