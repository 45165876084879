
  import {
    computed,
    defineComponent,
    onMounted,
    ref
  } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "Loading",
    layout: "portal",
    emits: ["animationFinished", "animationStarted"],
    setup(props, { emit }) {
      const numbers = ref<number>(0);
      const numberShown = ref<string>("0");
      const add = () => {
        if (numbers.value <= 1.9) {
          const number = numbers.value + 0.1;
          const rounded = Math.round(number * 10) / 10;
          const fixed = rounded.toFixed(1);
          numbers.value = parseFloat(fixed);
          numberShown.value = fixed;
        }
      };
      onMounted(() => {
        setInterval(add, 60);
        emit("animationStarted");
      });

      const handleAnimation = () => {
        emit("animationFinished");
      };

      const countFinished = computed(() => numberShown.value === "2.0");
      return {
        numbers,
        numberShown,
        handleAnimation,
        countFinished
      };
    }
  });
