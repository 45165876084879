import { render, staticRenderFns } from "./DialogInfo.vue?vue&type=template&id=409fba63&scoped=true&lang=pug"
import script from "./DialogInfo.vue?vue&type=script&lang=ts"
export * from "./DialogInfo.vue?vue&type=script&lang=ts"
import style0 from "./DialogInfo.vue?vue&type=style&index=0&id=409fba63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409fba63",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsButton: require('/workspace/packages/sharedUI/src/Button/Button.vue').default,LsButtonWrapper: require('/workspace/packages/sharedUI/src/Button/ButtonWrapper/ButtonWrapper.vue').default})
