import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=03fd46d9&scoped=true&lang=pug"
import script from "./Alert.vue?vue&type=script&lang=ts"
export * from "./Alert.vue?vue&type=script&lang=ts"
import style0 from "./Alert.vue?vue&type=style&index=0&id=03fd46d9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03fd46d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsIcon: require('/workspace/packages/sharedUI/src/Icon/Icon.vue').default})
