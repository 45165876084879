
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    name: "AnimatedLogo",
    props: {
      isReadyToStart: Boolean
    },
    emits: ["animationFinished"],
    setup(props, { emit }) {
      setTimeout(() => {
        emit("animationFinished");
      }, 3500);
    }
  });
