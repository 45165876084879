// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/header_christmas.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/header_christmas_600.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".seasonal-christmas[data-v-1283b628]{height:100%;left:0;position:absolute;top:0;width:100%}.main-header__christmas[data-v-1283b628]{animation:christmasAnimation-1283b628 1s forwards;animation-delay:2s;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x;background-size:contain;height:calc(100% - 2px);left:200px;min-width:930px;opacity:0;position:absolute;width:100%}.main-header__christmas--partner-logo[data-v-1283b628]{left:300px}@media(max-width:600px){.main-header__christmas[data-v-1283b628]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");left:0;min-width:600px}}@keyframes christmasAnimation-1283b628{0%{opacity:0;transform:scale(.99)}to{opacity:1;transform:scale(1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
