
  import { defineComponent } from "@nuxtjs/composition-api";

  export default defineComponent({
    props: {
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      showErrorMessage: {
        type: Boolean,
        required: true,
        default: false
      },
      isOpened: Boolean,
      hideDefaultMessage: {
        type: Boolean,
        default: false
      }
    },
    emits: ["close"],
    setup(props, { emit }) {
      const triggerAction = (actionType: string) => {
        emit(actionType);
      };

      return {
        triggerAction
      };
    }
  });
