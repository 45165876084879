import { render, staticRenderFns } from "./SignIn.vue?vue&type=template&id=df2d12ee&scoped=true&lang=pug"
import script from "./SignIn.vue?vue&type=script&lang=ts"
export * from "./SignIn.vue?vue&type=script&lang=ts"
import style0 from "./SignIn.vue?vue&type=style&index=0&id=df2d12ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2d12ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LsDialogInfo: require('/workspace/packages/sharedUI/src/Dialog/DialogInfo.vue').default,LsButton: require('/workspace/packages/sharedUI/src/Button/Button.vue').default,LsHrText: require('/workspace/packages/sharedUI/src/HrText.vue').default,LsAlert: require('/workspace/packages/sharedUI/src/Alert/Alert.vue').default,InternetTradingPolicyDetailsPopUp: require('/workspace/portal/hosting/components/InternetTradingPolicyDetailsPopUp.vue').default,LogoPortal: require('/workspace/portal/hosting/components/LogoPortal.vue').default})
