
  import {
    CHRISTMAS,
    HALLOWEEN,
    isActiveSeason
  } from "../use/useSiteConfiguration";
  import {
    Ref,
    computed,
    defineComponent,
    ref,
    useContext
  } from "@nuxtjs/composition-api";
  import { docToItem } from "@loadsure/utils";
  import { isSpecialPartner } from "@loadsure/core";
  import { useCurrentUser } from "../use/useFireAuth";

  export default defineComponent({
    name: "TheTopNavigation",

    setup() {
      const context = useContext();
      const {
        authUser,
        userAccess,
        allPartners,
        setActivePartnerId,
        partnerInfo
      } = useCurrentUser(context);

      const partnerName = computed(() => partnerInfo.value?.name);

      const switchAccountDialog = ref(false);
      const switchToAccount: Ref<string | undefined> = ref();

      const openSwitchAccountDialog = () => {
        switchAccountDialog.value = true;
      };

      const changeMyPartner = async (partnerId?: string) => {
        switchAccountDialog.value = false;
        switchToAccount.value = undefined;
        if (context.route.value.path !== "/dashboard") {
          context.redirect("/dashboard");
        }
        await setActivePartnerId(partnerId);
      };

      const partnerSwitchOptions = computed(() =>
        allPartners.docs?.map(docToItem)
      );

      const partnerLogoRendered = ref(false);
      const checkPartnerLogo = (partnerLogo) => {
        partnerLogoRendered.value = !!partnerLogo.value;
      };

      return {
        authUser,
        userAccess,
        partnerName,

        partnerSwitchOptions,
        isSpecialPartner,

        switchAccountDialog,
        switchToAccount,
        openSwitchAccountDialog,

        changeMyPartner,
        checkPartnerLogo,
        partnerLogoRendered,
        isActiveSeason,
        CHRISTMAS,
        HALLOWEEN
      };
    }
  });
