
import { defineComponent, useContext } from "@nuxtjs/composition-api";
import { useSiteConfigurationProvide } from "../use/useSiteConfiguration";

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup() {
    const context = useContext();
    useSiteConfigurationProvide(context);
  },
  data() {
    return {
      pageNotFound: "404 Not Found",
      otherError: "An error occurred"
    };
  }
});
